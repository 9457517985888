






import Vue from 'vue';
import vtable from '@/components/table.vue';
import { CaseType, CodeType, LinkCaseFileType, MarkType } from '@/types/sqlite';

export default Vue.extend({
	name: 'CaseProfile',
	components: {
		vtable
	},
	computed: {
		casesProfile() {
			const cases = this.$store.state.projectCurrent.cases.data
				.filter((v: CaseType) => v.status === 1)
				.map((v: CaseType) => ({
					value: `case-${v.id}`,
					text: v.name,

					id: v.id
				}));
			return {
				headers: [{ value: 'codeName', text: 'Code' }, ...cases],
				data: this.$store.state.projectCurrent.codes.data.map(
					(v: CodeType) => ({
						id: v.id,
						codeName: v.name,
						...cases.reduce(
							(p: any, c: any) => ({
								...p,
								[c.value]: this.codeInsideFiles(v.id, this.filesForCase(c.id))
							}),
							{}
						)
					})
				)
			};
		}
	},
	methods: {
		filesForCase(id: number) {
			return this.$store.state.projectCurrent.linksCase
				.filter((v: LinkCaseFileType) => v.caseid === id)
				.map((v: LinkCaseFileType) => v.fid);
		},
		codeInsideFiles(codeId: number, fileIds: number[]) {
			return this.$store.state.projectCurrent.fileCodes.data.filter(
				(v: MarkType) => v.cid === codeId && fileIds.includes(v.fid)
			).length;
		}
	}
});
